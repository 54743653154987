$(document).ready(function ()
{

	//rijksregisternummer validatie

	$('#rijksregisternummer').change(function(){
			let number = $(this).val();
			var check = number.match(/^[0-9]{2}[.\- ]{0,1}[0-9]{2}[.\- ]{0,1}[0-9]{2}[.\- ]{0,1}[0-9]{3}[.\- ]{0,1}[0-9]{2}$/);
			$(this).nextAll('.text-help').remove();

			if(!check){
				$(this).parent('fieldset').addClass('has-danger has-error');
				$('<div class="text-help help-block with-errors"><ul class="list-unstyled"><li>rijksregisternummer niet correct.</li></ul></div>').insertAfter($(this));
				$(":submit").addClass('disabled');
			}
	});

	$('#rijksregisternummer').keydown(function(){
			$(this).nextAll('.text-help').remove();
	});

	/* MOBILE NAV */

	// Don't animate menu if it has to be shown by default
	if ($(window).width() > 800 && $('body').hasClass('has-children')) {
		$('body').removeClass('has-children');
		$('body').addClass('is-nav-open');
	}

	$('.toggle-nav').click(function(){
		$('body').toggleClass('is-nav-open');

		setTimeout(function() {
			$('.grid.masonry').masonry('layout');
			$('.pic-grid.masonry').masonry('layout');
		}, 310);
	});


	$('.nav-line').click(function(){
		$('body').toggleClass('is-nav-open');
		
		setTimeout(function() {
			$('.grid.masonry').masonry('layout');
			$('.pic-grid.masonry').masonry('layout');
		}, 310);
	});


	// Youtube/Vimeo thumbs
	$("[class*='rc-video'] .thumb").click( startVideo );

	function startVideo(e) {
		var block = $(e.currentTarget);
		var iframe = $('iframe', block.parent());
		var src = iframe.attr('src');

		if (src.indexOf('autoplay=0') > -1) {
			iframe.attr('src', src.replace('autoplay=0','autoplay=1') );
		} else {
			iframe.attr('src', src + '?autoplay=1');
		}
		block.fadeOut(150);
	}


	// Lightbox - UNCOMMENT ME BIJ KOPPELEN

	$('a.lightbox').fancybox({
		overlayOpacity: 0.8,
		overlayColor: 'black',
		padding: 0,
		titleShow: true,
		mouseWheel: false,
		margin: [50, 20, 20 ,20],
		nextEffect: 'none',
		prevEffect: 'none',
		beforeLoad: function(){
			this.title = '<strong>'+this.title+'</strong>';
			var desc = $(this.element).attr('data-desc');
			if ( desc && desc != '') {
				this.title = this.title + '<br/>' + desc;
			}
		},
		beforeShow: function () {
			$("body *:not(.fancybox-overlay, .fancybox-overlay *)").addClass("blur");
	    },
	    afterClose: function () {
			$("body *").removeClass("blur");
	    }
	});

	$('a.lightbox_teacher').fancybox({
		overlayOpacity: 0.8,
		overlayColor: 'black',
		padding: 0,
		titleShow: true,
		mouseWheel: false,
		margin: [50, 20, 20 ,20],
		nextEffect: 'none',
		prevEffect: 'none',
		beforeLoad: function(){

			var el, id = $(this.element).data('teacher-id');
            if (id) {
                el = $('#' + id);

                if (el.length) {
                    this.title = el.html();
                }
            }

			this.title = '<strong>'+this.title+'</strong>';
			var desc = $(this.element).attr('data-desc');
			if ( desc && desc != '') {
				this.title = this.title + '<br/>' + desc;
			}
		},
		beforeShow: function () {
			$("body *:not(.fancybox-overlay, .fancybox-overlay *)").addClass("blur");
	    },
		afterShow: function() {
	    	$(".fancybox-title-float-wrap .child").find('*').removeClass("blur");
	    },
	    afterClose: function () {
			$("body *").removeClass("blur");
	    }
	});
});

$(window).load(function(){
	$('.grid.masonry').masonry({
		// options
		columnWidth: '.grid__sizer',
		itemSelector: '.grid__item',
		gutter: 0
	});

	$('.pic-grid.masonry').masonry({
		// options
		columnWidth: '.pic-grid__sizer',
		itemSelector: '.pic-grid__item',
		gutter: 0
	});
});